import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { KeyOutlined, LoginOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";

import User from "../../../models/user";
import UserService from "../../../services/UserService";

import "./createUser.css";

interface Props {
  setUser: Function;
  handleCloseCreateUser: Function;
}

const CreateUser = ({
  setUser,
  handleCloseCreateUser,
}: Props) => {
  const { t } = useTranslation();

  const [error, setError] = useState<number>(0);

  const schema = yup.object().shape({
    lastname: yup
      .string()
      .required(
        t("error.required", { field: t("user.lastname").toLowerCase() })
      )
      .test(
        "3Len",
        t("error.minLen", { field: "3" }),
        (value: string) => value.length >= 3
      )
    ,
    firstname: yup
      .string()
      .required(
        t("error.required", { field: t("user.firstname").toLowerCase() })
      )
      .test(
        "3Len",
        t("error.minLen", { field: "3" }),
        (value: string) => value.length >= 3
      ),
    password: yup
      .string()
      .required(
        t("error.required", {
          field: t("user.password").toLowerCase(),
        })
      )
      .test(
        "4Len",
        t("error.minLen", { field: "4" }),
        (value: string) => value.length >= 4
      ),
    confirmPassword: yup
      .string()
      .required(
        t("error.required", {
          field: t("user.password").toLowerCase(),
        })
      )
      .test(
        "4Len",
        t("error.minLen", { field: "4" }),
        (value: string) => value.length >= 4
      )
      .oneOf(
        [yup.ref("password")],
        t("error.passwordsNotMatch").toLowerCase()
      ),
    address: yup
      .string()
      .required(
        t("error.required2", { field: t("user.address").toLowerCase() })
      )
      .test(
        "3Len",
        t("error.minLen", { field: "3" }),
        (value: string) => value.length >= 3
      ),
    phoneNumber: yup
      .string()
      .required(
        t("error.required", {
          field: t("user.phone").toLowerCase(),
        })
      )
      .matches(/^(\+|00)((32)(\d){8}|(33)(\d){9})$/, t("error.phoneFormat2"))
      .test(
        "10Len",
        t("error.minLen", { field: "11" }),
        (value: string) => value.length >= 11
      )
  });

  const formik = useFormik({
    initialValues: {
      lastname: "",
      firstname: "",
      password: "",
      confirmPassword: "",
      address: "",
      phoneNumber: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      let newUser: User = {
        lastname: values.lastname,
        firstname: values.firstname,
        password: values.password,
        address: values.address,
        login: values.phoneNumber,
      };

      console.log(newUser);

      UserService.saveUser(newUser).then((response) => {
        console.log(response);
        if (response === 200 || response === 0) {
          setUser(newUser);
          handleCloseCreateUser(true);
        } else {
          setError(response);
          // console.log(error);
        }
      });
    },
  });

  useEffect(() => {
    console.log(error);
  }, [error]);

  return (
    <Card className="CreateUser" elevation={10}>
      <Box className="pageUser">
        <form onSubmit={formik.handleSubmit}>
          <Box className="loginUser1">
            <Box className="cardLine4">
              <Typography className="descriptifUser">
                {t("user.lastname") + " : "}
              </Typography>
              <Box className="loginUser2" display={"flex"}>
                <TextField
                  className="loginUser3"
                  placeholder={t("user.lastname")}
                  //label={t("user.lastname")}
                  id="lastname"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastname}
                  name="lastname"
                  error={
                    formik.touched.lastname && Boolean(formik.errors.lastname)
                  }
                  helperText={formik.touched.lastname && formik.errors.lastname}
                />
              </Box>
            </Box>
            <Box className="cardLine4">
              <Typography className="descriptifUser">
                {t("user.firstname") + " : "}
              </Typography>
              <Box className="loginUser2" display={"flex"}>
                <TextField
                  className="loginUser3"
                  placeholder={t("user.firstname")}
                  //label={t("user.firstname")}
                  id="firstname"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstname}
                  name="firstname"
                  error={
                    formik.touched.firstname && Boolean(formik.errors.firstname)
                  }
                  helperText={
                    formik.touched.firstname && formik.errors.firstname
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box className="loginUser1">
            <Box className="cardLine4">
              <Typography className="descriptifUser">
                {t("user.password") + " : "}
              </Typography>
              <Box className="loginUser2" display={"flex"}>
                <TextField
                  className="loginUser3"
                  placeholder={t("user.password")}
                  //label={t("user.password")}
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyOutlined />
                      </InputAdornment>
                    ),
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  name="password"
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Box>
            </Box>
            <Box className="cardLine4">
              <Typography className="descriptifUser">
                {t("common.passwordConfirm") + " : "}
              </Typography>
              <Box className="loginUser2" display={"flex"}>
                <TextField
                  className="loginUser3"
                  placeholder={t("common.passwordConfirm")}
                  //label={t("user.passwordConfirm")}
                  id="confirmPassword"
                  type="password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyOutlined />
                      </InputAdornment>
                    ),
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  name="confirmPassword"
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box className="loginUser1">
            <Box className="cardLine4">
              <Typography className="descriptifUser">
                {t("user.phone") + " : "}
              </Typography>
              <Box className="loginUser2" display={"flex"}>
                <TextField
                  className="loginUser3"
                  placeholder={t("user.phone")}
                  //label={t("user.phone")}
                  id="phoneNumber"
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LoginOutlined />
                      </InputAdornment>
                    ),
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                  name="phoneNumber"
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box className="loginUser1">
            <Box className="cardLine5">
              <Typography className="descriptifUser">
                {t("user.address") + " : "}
              </Typography>
              <Box className="loginUser2" display={"flex"}>
                <TextField
                  maxRows={5}
                  minRows={5}
                  multiline
                  className="loginUser4"
                  placeholder={t("user.address")}
                  //label={t("user.address")}
                  id="address"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  name="address"
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Box>
            </Box>
          </Box>
          <Box className="recordButton">
            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: "#fbc02c",
                color: "#3465a4",
                marginTop: "5%",
                marginBottom: "7%",
                fontWeight: "800",
                fontSize: "1rem",
              }}
            >
              {t("common.register")}
            </Button>
          </Box>
          <Box>
            {(error !== 0) ?
              <>
                <Typography className="descriptifUser3" color="red">
                  {(error === 999)
                    ? t("common.userexist")
                    : (error === 990)
                      ? t("common.unauthorized")
                      : t("common.unabletoregister")}
                </Typography>
                <Typography className="descriptifUser3">
                  {(error === 999) ? t("common.connect") : t("common.unabletoregistermessage")}
                </Typography>
              </>
              : <></>
            }
          </Box>
        </form>
      </Box >
    </Card >
  );
};

export default CreateUser;
