export const mockDataPizza = [
	{
		"id": 1,
		"name": "Margherita",
		"description": "Sauce tomate à l'origan et mozzarella",
		"image": "assets/images/margherita.jpg",
		"price": 7.90
	},
	{
		"id": 2,
		"name": "4 Fromages",
		"description": "Sauce tomate à l'origan ou crème fraîche légère, mozzarella, fromage de chèvre, emmental et Fourme d'Ambert AOP",
		"image": "assets/images/4fromages.jpg",
		"price": 10.50
	},
	{
		"id": 3,
		"name": "Campagnarde",
		"description": "Crème fraîche légère, mozzarella, lardons, oignons rouges frais et champignons frais",
		"image": "assets/images/campagnarde.jpg",
		"price": 10.00
	},
	{
		"id": 4,
		"name": "Chèvre Miel",
		"description": "Crème fraîche légère, mozzarella, fromage de chèvre, miel",
		"image": "assets/images/chevre-miel.jpg",
		"price": 9.50
	},
	{
		"id": 5,
		"name": "Chicken Barbecue",
		"description": "Sauce barbecue, mozzarella, Poulet rôti, oignons rouges frais, champignons frais et poivrons verts frais",
		"image": "assets/images/chicken-bbq.jpg",
		"price": 10.00
	},
	{
		"id": 6,
		"name": "Hawaïenne",
		"description": "Sauce tomate à l'origan, mozzarella, jambon et ananas",
		"image": "assets/images/hawaienne.jpg",
		"price": 9.50
	},
	{
		"id": 7,
		"name": "Nordique",
		"description": "Crème fraîche légère, mozzarella et saumon fumé de Norvège",
		"image": "assets/images/nordique.jpg",
		"price": 10.50
	},
	{
		"id": 8,
		"name": "Orientale",
		"description": "Sauce tomate à l'origan, mozzarella, merguez et champignons frais",
		"image": "assets/images/orientale.jpg",
		"price": 9.50
	},
	{
		"id": 9,
		"name": "Pepperoni",
		"description": "Sauce tomate à l'origan, mozzarella et saucisse pepperoni",
		"image": "assets/images/pepperoni.jpg",
		"price": 10.50
	},
	{
		"id": 10,
		"name": "Reine",
		"description": "Sauce tomate à l'origan, mozzarella, jambon et champignons frais",
		"image": "assets/images/reine.jpg",
		"price": 9.50
	},
	{
		"id": 11,
		"name": "Texane Barbecue",
		"description": "Sauce barbecue, mozzarella, jambon, emietté au boeuf, lardons, champignons frais et oignons rouges frais.",
		"image": "assets/images/texan-bbq.jpg",
		"price": 10.00
	},
	{
		"id": 12,
		"name": "Végétarienne",
		"description": "Sauce tomate à l'origan, mozzarella, champignons frais, oignons rouges frais, poivrons verts frais, tomates fraîches et Olives noires",
		"image": "assets/images/vegetarienne.jpg",
		"price": 19.50
	}
];

