import { useEffect, useState } from "react";

import { KeyOutlined, LoginOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import * as yup from "yup";
import AuthenticationService from "../../services/AuthenticationService";
import CreateUser from "../../components/modals/createUser/createUser";

import "./loginPage.css";
import Congratulation from "../../components/modals/congratulation/congratulation";
import User from "../../models/user";

interface Props {
  setIsAuthenticated: Function;
}

const LoginPage = ({ setIsAuthenticated }: Props) => {
  const { t } = useTranslation();

  const [error, setError] = useState<boolean>(false);

  const [openCreateUser, setOpenCreateUser] = useState(false);

  const handleOpenCreateUser = () => {
    setOpenCreateUser(true);
  };

  const handleCloseCreateUser = (retour: boolean) => {
    if (retour === true) {
      handleOpenCongratUser();
    }

    setOpenCreateUser(false);
  };

  const [user, setUser] = useState<User>();

  const [isLogin, setIsLogin] = useState<boolean>(false);

  const [openCongratUser, setOpenCongratUser] = useState(false);

  const handleOpenCongratUser = () => {
    setOpenCongratUser(true);
  };

  const handleCloseCongratUser = () => {
    setOpenCongratUser(false);
  };

  useEffect(() => {
    if (user && isLogin) {
      AuthenticationService.login(user.login, user.password).then(
        (response) => {
          console.log(response);

          setIsAuthenticated(response);
          setError(!response);
        }
      );
    }
  }, [user, isLogin]);

  const schema = yup.object().shape({
    login: yup
      .string()
      .required(t("error.required", { field: t("user.phone").toLowerCase() }))
      .matches(/^(\+|00)((32)(\d){8}|(33)(\d){9})$/, t("error.phoneFormat2"))
      .test(
        "11Len",
        t("error.minLen", { field: "11" }),
        (value: string) => value.length >= 11
      ),
    password: yup
      .string()
      .required(
        t("error.required", { field: t("user.password").toLowerCase() })
      )
      .test(
        "4Len",
        t("error.minLen", { field: "4" }),
        (value: string) => value.length >= 4
      ),
  });

  const formik = useFormik({
    initialValues: {
      login: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      AuthenticationService.login(values.login, values.password).then(
        (response) => {
          setIsAuthenticated(response);
          setError(!response);
        }
      );
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Card className="login">
          <Box className="pageLogin">
            <Box className="cardLine4">
              <Typography className="descriptif">
                {t("user.phone") + " : "}
              </Typography>
              <Box className="login2" display={"flex"}>
                <TextField
                  className="login3"
                  placeholder={t("user.phone")}
                  //label={t("user.phone")}
                  id={t("user.phone")}
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LoginOutlined />
                      </InputAdornment>
                    ),
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.login}
                  name="login"
                  error={formik.touched.login && Boolean(formik.errors.login)}
                  helperText={formik.touched.login && formik.errors.login}
                />
              </Box>
            </Box>
            <Box className="cardLine4">
              <Typography className="descriptif">
                {t("user.password") + " : "}
              </Typography>
              <Box className="login2" display={"flex"}>
                <TextField
                  className="login3"
                  placeholder={t("user.password")}
                  //label={t("user.password")}
                  id={t("user.password")}
                  type="password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyOutlined />
                      </InputAdornment>
                    ),
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  name="password"
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Box>
            </Box>
            <Typography
              className="descriptif3 pointer"
              onClick={() => {
                handleOpenCreateUser();
              }}
            >
              {t("common.noLogin")}
            </Typography>

            <Box className="loginButton">
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "#fbc02c",
                  color: "#3465a4",
                  // marginLeft: "15%",
                  // marginRight: "15%",
                  marginTop: "5%",
                  marginBottom: "7%",
                  fontWeight: "800",
                  fontSize: "1rem",
                }}
              >
                {t("common.connect")}
              </Button>
            </Box>

            <Box>
              <>
                {error && (
                  <Typography className="descriptif3" color="red">
                    {t("common.loginError")}
                  </Typography>
                )}
              </>
            </Box>
          </Box>
        </Card>
      </form>
      <Modal
        open={openCreateUser}
        onClose={handleCloseCreateUser}
        aria-labelledby={t("common.pop-up")}
        aria-describedby={t("common.createUser")}
      >
        <>
          <CreateUser
            setUser={setUser}
            handleCloseCreateUser={handleCloseCreateUser}
          />
        </>
      </Modal>
      <Modal
        open={openCongratUser}
        onClose={handleCloseCongratUser}
        aria-labelledby={t("common.pop-up")}
        aria-describedby={t("common.congratUser")}
      >
        <>
          <Congratulation
            setIsLogin={setIsLogin}
            handleCloseCongratUser={handleCloseCongratUser}
          />
        </>
      </Modal>
    </>
  );
};
export default LoginPage;
