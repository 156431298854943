import Order from "../models/order";
import AuthenticationService from "./AuthenticationService";

export default class OrderService {

    static async saveOrder(order: Order): Promise<Order> {
        console.log("je suis dans saveOrder", order);
        return fetch("https://mariospizzas.tsao-tsao.com:58443/order/update", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: AuthenticationService.getJwt()
            },
            body: JSON.stringify(order),
        })
            .then(response => response.json())
            .catch((reason) => { console.error(reason); throw reason; })
    }
}
