import User from "../models/user";
import UserResponse from "../models/userResponse";

export default class UserService {

	static async call(user: User): Promise<UserResponse | undefined> {

		const response = await fetch("https://mariospizzas.tsao-tsao.com:58443/user/create", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(user),
		});
		if (response.ok) {
			return await response.json();
		} else {
			console.log(response);
			return await response.json();
		}
	}

	static async saveUser(user: User): Promise<number> {
		let retour = 0;
		await this.call(user)
			.then((response) => {
				if (response !== undefined) {
					console.log("et là ?", response);
					console.log(response.status, response.message);

					if (response.status === 999) {
						retour = response.status;
					} else if (response.status === 0) {
						console.log(response.status, response.message);
						retour = response.status;
					}
				}
			})
			.catch((err) => {
				/*if (response.status === 401) {
					retour = response.status;
				} else { */
				console.log("Je passe vraiment là ?");
				console.log(err);
				console.log(err.message);
				retour = 990;
				/* } */
			});

		return new Promise((resolve) => resolve(retour));
	}

	static isAuthenticated(): boolean {
		if (localStorage.getItem("jwt") !== null) {
			const expiration = localStorage.getItem("expiration");

			return expiration !== null && Date.parse(expiration) > Date.now();
		} else {
			return false;
		}
	}

	static getJwt(): any {
		return localStorage.getItem("jwt");
	}
}

