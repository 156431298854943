import { useState } from "react";
import { Route, Routes } from "react-router-dom";

import Header from "./components/header/header";

import LoginPage from "./pages/loginPage/loginPage";

import Footer from "./components/footer/footer";

import AuthenticationService from "./services/AuthenticationService";

import PizzaList from "./pages/pizzaList/pizzaList";

import "./App.css";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    AuthenticationService.isAuthenticated()
  );

  // console.log(isAuthenticated);

  return (
    <>
      <div className="App">
        <Header />
        <main>
          {isAuthenticated ? (
            <Routes>
              <Route
                path="/"
                element={<PizzaList isAuthenticated={isAuthenticated} />}
              />
            </Routes>
          ) : (
            <LoginPage setIsAuthenticated={setIsAuthenticated} />
          )}
        </main>
        <Footer />
      </div>
    </>
  );
}

export default App;
