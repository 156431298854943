import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  AppBar,
  Box,
  Typography,
} from "@mui/material";

import "./header.css";

const Header = () => {
  const { t } = useTranslation();

  return (
    <AppBar position="fixed" color="primary" sx={{ top: 0, bottom: "auto", bgcolor: '#3b438b' }}>
      <Box className="headerGlobal">
        <Box className="headerLogo">
          <NavLink to="/">
            <img
              src="/assets/images/logo.png"
              alt="Logo Mario Pizza"
              style={{ width: "100px" }}
            />
          </NavLink>
        </Box>
        <Box className="headerTitle">
          <Typography variant="h1" className="headerTypo">{t("common.title")}</Typography>
        </Box>
      </Box>
    </AppBar>
  );
};
export default Header;
