import Pizza from "../models/pizza";
import AuthenticationService from "./AuthenticationService";

export default class PizzaService {

    static pizzas: Pizza[];

    static async loadPizzas(): Promise<Pizza[]> {
        return fetch("https://mariospizzas.tsao-tsao.com:58443/pizza/", {
            headers: {
                authorization: AuthenticationService.getJwt()
            }
        })
            .then(response => response.json())
            .catch((reason) => { console.error(reason); throw reason; })
    }

    static async getPizzas(): Promise<Pizza[]> {

        if (this.pizzas === undefined) {
            await this.loadPizzas().then((pizzaList) => (this.pizzas = pizzaList));
        }

        return new Promise((resolve) => resolve(this.pizzas));
    }
}
