import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import Pizza from "../../models/pizza";
import QuantityInput from "../quantity/quantityInput";

import "./pizzaItem.css";

interface Props {
  pizza: Pizza;
  handlePizzaQuantity: Function;
}

const PizzaItem = ({ pizza, handlePizzaQuantity }: Props) => {
  const { t } = useTranslation();

  // console.log(pizza);
  /*
  const handleChangeTotal = (event: any) => {
    console.log(event);
  };
*/
  return (
    <>
      <Card className="pizzaItem">
        <Box className="pizzaContent" sx={{ boxShadow: 3 }}>
          <CardMedia
            className="mediacard"
            component="img"
            image={"./assets/images/" + pizza.image}
            alt={t("pizza " + pizza.name)}
            height="150"
          />
          <CardContent className="contentcard">
            <Box className="droite">
              <Box className="droite_haut">
                <Box className="price">
                  <Typography
                    alignItems="center"
                    fontSize="48px"
                    align="center"
                  >
                    {pizza.price.toFixed(2) + "€"}
                  </Typography>
                </Box>
              </Box>
              <Box className="droite_bas">
                <Box className="droite_bas_label">
                  <Typography align="left">quantité</Typography>
                </Box>
                <Box className="droite_bas_compteur">
                  <Box>
                    <QuantityInput
                      pizzaId={pizza.id}
                      handlePizzaQuantity={handlePizzaQuantity}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="gauche">
              <Box className="gauche_haut" height="40px">
                <Typography variant="h4" align="left" height="4em">
                  {pizza.name}
                </Typography>
              </Box>
              <Box className="gauche_bas">
                <Typography variant="body1" height="5em">
                  {t("pizzadesc." + pizza.id)}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Box>
      </Card>
    </>
  );
};

export default PizzaItem;
