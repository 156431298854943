import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Modal, Typography } from "@mui/material";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";

import { useFormik } from "formik";
import * as yup from "yup";

import Pizza from "../../models/pizza";
import Order from "../../models/order";
import LineOrder from "../../models/lineOrder";

import { mockDataPizza } from "../../data/MockData";

import PizzaItem from "../../components/pizzaItem/pizzaItem";

import OrderService from "../../services/orderService";
import PizzaService from "../../services/pizzaServices";

import "./pizzaList.css";
import ValidOrder from "../../components/modals/validOrder/validOrder";

interface Props {
  isAuthenticated: Boolean;
}

const PizzaList = ({ isAuthenticated }: Props) => {
  const { t } = useTranslation();

  const [order, setOrder] = useState<Order>({
    id: 0,
    userId: 0,
    totalAmount: 0,
    dateOrder: new Date(),
    lines: [],
  });

  const [openValid, setOpenValid] = useState(false);

  const handleOpenValid = () => {
    setOpenValid(true);
  };

  const handleCloseValid = () => {
    setOpenValid(false);
  };

  const schema = yup.object().shape({});

  const formik = useFormik({
    initialValues: {
      id: order ? order.id : 0,
      userId: order ? order.userId : 0,
      date: order ? order.dateOrder : new Date(),
      total: order ? order.totalAmount : 0,
      linesOrder: order ? order.lines : ([] as LineOrder[]),
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (values.total > 0) {
        let newOrder: Order = {
          id: values.id,
          userId: values.userId,
          dateOrder: values.date,
          totalAmount: values.total,
          lines: values.linesOrder,
        };

        setOrder(newOrder);

        console.log("newOrder", newOrder);

        OrderService.saveOrder(newOrder).then((response) => {
          console.log("Je reviens de saveOrder", response);
          console.log(response);
          handleOrderChange(order);
          handleOpenValid();
        });
      }
    },
  });

  const handleOrderChange = (order: Order) => {
    console.log(
      "je récupère le numéro de commande et je remets à zéro la commande"
    );
    console.log(order);
  };

  const [pizzas, setPizzas] = useState<Pizza[]>([]);

  const [prixTotal, setPrixTotal] = useState<number>(0);

  const handlePizzaQuantity = (pizzaId: number) => {

    let totalPrice: number = 0;

    formik.values.linesOrder = [];

    mockDataPizza.forEach((pizza) => {
      let quantity = parseFloat(
        (document.getElementById("pizza_" + pizza.id) as HTMLInputElement).value
      );

      if (quantity) {

        totalPrice += quantity * pizza.price;

        formik.values.linesOrder.push({
          id: 0,
          ordId: 0,
          pizId: pizza.id,
          quantity: quantity,
        });
      }
    });

    setPrixTotal(totalPrice);

    formik.values.total = totalPrice;
  };

  useEffect(() => {
    if (isAuthenticated) {
      // uniquement pour test de la version Mario-s-pizzas-back-V0.04c-bis du back
      // setPizzas(mockDataPizza);
      // si accès à la base de données
      PizzaService.getPizzas().then((value) => setPizzas(value));
    } else {
      setPizzas(mockDataPizza);
    }
  }, [isAuthenticated]);

  return (
    <>
      <Box className="main">
        <form onSubmit={formik.handleSubmit}>
          <Box className="mainBox">
            <Box className="mainBox2">
              <Box width="60%">
                <Typography variant="h2" fontSize="48px" fontWeight="500">
                  {t("common.selectPizza", { count: pizzas?.length })}
                </Typography>
              </Box>

              <Box className="totalprice">
                <Typography id="prixTotal" variant="h2" fontWeight="500">
                  {prixTotal.toFixed(2) + "€"}
                </Typography>
              </Box>
              <Box>
                <Button
                  type="submit"
                  color="primary"
                  aria-label="home"
                  style={{
                    marginLeft: "30px",
                    marginTop: "5px",
                    marginRight: "30px",
                    maxWidth: "60px",
                    maxHeight: "60px",
                    minWidth: "60px",
                    minHeight: "60px",
                  }}
                >
                  <LocalGroceryStoreOutlinedIcon className="svg_icons" />
                </Button>
              </Box>
            </Box>
            <Box className="mainBox3">
              <Box className="itemsList" id="list">
                {pizzas?.map((pizza: Pizza) => (
                  <Box key={`${pizza.id}`}>
                    <PizzaItem
                      pizza={pizza}
                      handlePizzaQuantity={handlePizzaQuantity}
                    />
                  </Box>
                ))}

                <Box className="pizzaContent" sx={{ height: "50px" }}></Box>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>

      <Modal
        open={openValid}
        onClose={handleCloseValid}
        aria-labelledby={t("order." + (order ? order.id : 0))}
        aria-describedby={t("order." + (order ? order.id : 0))}
      >
        <>
          <ValidOrder
            orderId={order ? order.id : 0}
            handleCloseValid={handleCloseValid}
            handleOrderChange={handleOrderChange}
          />
        </>
      </Modal>
    </>
  );
};

export default PizzaList;
