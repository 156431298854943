import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";

import "./congratulation.css";
import { useTranslation } from "react-i18next";

interface Props {
  setIsLogin: Function;
  handleCloseCongratUser: Function;
}

const Congratulation = ({ setIsLogin, handleCloseCongratUser }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Box className="congrat">
        <Typography className="congrattxt">
          {t("common.congratUser")} !
        </Typography>
        <Typography className="congrattxt">
          Vous êtes prêts à commander.
        </Typography>
        <Button
          onClick={() => {
            setIsLogin(true);
            handleCloseCongratUser();
          }}
        >
          <Typography className="congratlink">
            Passer votre première commande
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default Congratulation;
