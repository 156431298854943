import { MopedOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

import "./validOrder.css";
import { Link } from "react-router-dom";

interface Props {
  orderId: number;
  handleCloseValid: Function;
  handleOrderChange: Function;
}

const ValidOrder = ({
  orderId,
  handleCloseValid,
  handleOrderChange,
}: Props) => {
  return (
    <>
      <Box className="validcmd">
        <Typography className="validcmdtxt">
          Votre commande est en préparation.
        </Typography>
        <Typography className="validcmdtxt">
          Elle sera livré dans 30 minutes
        </Typography>
        <Box className="validcmdiconbox">
          <MopedOutlined className="validcmdicon" />
        </Box>
        <Button
          onClick={() => {
            window.location.reload();
          }}
        >
          <Typography className="validcmdlink">
            Retour à la page de commande
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default ValidOrder;
